import React from "react";
import { Link } from "react-router-dom";
import Navigation from "../components/Navigation";
import Layout from "../components/Layout";
import Header from "../components/Header";
import headerData from "../content/header.json";

const title = "TOHA Heavy Industries - 404";

const NotFound = () => {
  return (
    <>
      <Header title={title} description={headerData.description} />
      <Layout>
        <Navigation />
        <div>
          <h1>404</h1>
          <h2>Nothing to see here!</h2>
          <p>
            <Link to="/">Go to the home page</Link>
          </p>
        </div>
      </Layout>
    </>
  );
};

export default NotFound;
