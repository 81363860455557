import React, { useContext } from "react";
import { AuthProps } from "../types";
import NavLink from "../utils/navlinks";
import { buttonVariants } from "../utils/button";
import Logo from "./Logo";
import navLinks from "../content/navigation.json";
import { ThemeContext } from "../context/ThemeContext";
import Toggle from "react-toggle";
import "react-toggle/style.css";

const authBasePath =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_ORY_SDK_URL
    : process.env.REACT_APP_PROD_ORY_SDK_URL;

const Navigation: React.FC<AuthProps> = ({ session, logoutUrl }) => {
  const userEmail = session?.identity?.traits?.email || "friend";
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <div className="w-full flex justify-between items-center pb-4">
        <Logo
          src="./logo.png"
          alt="TOHA Heavy Industries Logo"
          companyName="TOHA"
          size="h-16"
        />
        {navLinks.map((link, index) => (
          <NavLink key={index} to={link.path}>
            {link.name}
          </NavLink>
        ))}
        <Toggle
          checked={themeContext?.theme === "dark"}
          icons={{ checked: "🌙", unchecked: "🔆" }}
          onChange={themeContext?.toggleTheme}
        />
        {session ? (
          <>
            <span className="text-lg font-bold"></span>
            <a
              href={"dashboard"}
              className={buttonVariants({ variant: "outline", size: "md" })}
            >
              Welcome, {userEmail} 👋
            </a>
            <a
              href={authBasePath + "/ui/settings"}
              className={buttonVariants({ variant: "outline", size: "md" })}
            >
              Settings
            </a>
            <a
              href={logoutUrl}
              className={buttonVariants({ variant: "outline", size: "md" })}
            >
              Logout
            </a>
          </>
        ) : (
          <>
            <a
              href={authBasePath + "/ui/login"}
              className={buttonVariants({ variant: "outline", size: "lg" })}
            >
              Log in
            </a>
            <a
              href={authBasePath + "/ui/registration"}
              className={buttonVariants({ variant: "solid", size: "lg" })}
            >
              Sign up
            </a>
          </>
        )}
      </div>
    </>
  );
};

export default Navigation;
