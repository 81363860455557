import React from "react";
import { Link } from "react-router-dom";

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
}

const NavLink: React.FC<NavLinkProps> = ({ to, children }) => {
  return (
    <div>
      <Link to={to} className="p-2 bg-transparent border-b border-purple-400">
        {children}
      </Link>
    </div>
  );
};

export default NavLink;
