import React, { createContext, useContext, useState, useEffect } from "react";
import { Configuration, FrontendApi, Session } from "@ory/client";
import { AxiosError } from "axios";

const AuthContext = createContext<any>(null);

const authBasePath =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_ORY_SDK_URL
    : process.env.REACT_APP_PROD_ORY_SDK_URL;

const ory = new FrontendApi(
  new Configuration({
    basePath: authBasePath,
    baseOptions: {
      withCredentials: true,
    },
  })
);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [session, setSession] = useState<Session | null>();
  const [logoutUrl, setLogoutUrl] = useState<string | null>();
  const [error, setError] = useState<any>();

  useEffect(() => {
    if (session || error) {
      return;
    }
    ory
      .toSession()
      .then(({ data: session }) => {
        setSession(session);

        return ory.createBrowserLogoutFlow().then(({ data }) => {
          setLogoutUrl(data.logout_url);
        });
      })
      .catch((err: AxiosError) => {
        // An error occurred while loading the session or fetching
        // the logout URL. Let's show that!
        setError(err);
      });
  }, [session, error]);

  return (
    <AuthContext.Provider value={{ session, logoutUrl, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
