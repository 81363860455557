type ButtonVariant = "solid" | "outline";
type ButtonSize = "sm" | "md" | "lg" | "xl";

interface ButtonVariantsProps {
  variant: ButtonVariant;
  size: ButtonSize;
}

export const buttonVariants = ({
  variant,
  size,
}: ButtonVariantsProps): string => {
  let baseClass = "btn";
  let variantClass = "";
  let sizeClass = "";

  if (variant === "solid") {
    variantClass = "bg-purple-500 border border-purple-400";
  } else {
    variantClass = "bg-transparent border border-purple-400";
  }

  if (size === "sm") {
    sizeClass = "px-2 py-1 text-sm";
  } else if (size === "md") {
    sizeClass = "px-4 py-2 text-base";
  } else if (size === "lg") {
    sizeClass = "px-6 py-3 text-lg";
  } else {
    sizeClass = "px-8 py-4 text-xl";
  }

  return `${baseClass} ${variantClass} ${sizeClass} inline-flex items-center justify-center rounded-md font-medium focus:outline-none`;
};
