import React from "react";
import { CookieIcon } from "@radix-ui/react-icons";
import { buttonVariants } from "../utils/button";
import { CTAProps, ChildrenProps } from "../types";

const CTABanner: React.FC<CTAProps> = ({ title, description, buttons }) => (
  <div className="p-16 text-center">
    <h2 className="text-xl font-semibold mb-4">{title}</h2>
    <p className="text-md mb-6">{description}</p>
    <div>{buttons}</div>
  </div>
);

const Section: React.FC<ChildrenProps> = ({ children }) => (
  <section>{children}</section>
);

const CTA: React.FC<CTAProps> = ({
  title,
  description,
  buttonText,
  buttonUrl,
}) => {
  return (
    <Section>
      <CTABanner
        title={title}
        description={description}
        buttons={
          <a
            href={buttonUrl}
            className={buttonVariants({ variant: "outline", size: "xl" })}
          >
            <CookieIcon className="mr-2 size-8" />
            {buttonText}
          </a>
        }
      />
    </Section>
  );
};

export default CTA;
