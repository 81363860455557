import React from "react";
import { FooterProps } from "../types";

const Footer: React.FC<FooterProps> = ({ year, company, about }) => {
  return (
    <>
      <footer className="py-2">
        <div className="container mx-auto flex justify-between items-center">
          <p className="text-sm">
            © {year} {company}. All rights reserved.
          </p>
          <a
            href={about}
            className="text-sm hover:text-gray-700"
            title="About Us"
          >
            About
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
