import React from "react";
import heroContent from "../content/hero.json"; // Adjust the path to where your hero.json is located

const Hero = () => {
  return (
    <div className="flex flex-col items-center justify-center py-32 h-full bg-gradient-to-tl from-slate-400 dark:from-slate-600 to-white rounded-lg shadow-2xl">
      <div className="flex items-center p-8"></div>
      <h3 className="text-5xl font-bold mb-3 text-center">
        {heroContent.title1}
      </h3>
      <p className="opacity-80 text-lg max-w-prose pt-4 text-center">
        {heroContent.paragraph1}
      </p>
      <p className="text-red-500 text-lg pt-4 max-w-prose text-center">
        {heroContent.demoNotice}
      </p>
    </div>
  );
};

export default Hero;
