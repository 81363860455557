import React from "react";

interface LogoProps {
  src: string;
  alt: string;
  companyName: string;
  size?: string;
}

const Logo: React.FC<LogoProps> = ({
  src,
  alt,
  companyName,
  size = "h-16",
}) => {
  return (
    <div className="flex items-center">
      <img src={src} alt={alt} className={size} />
      <h1 className="px-2 text-xl font-bold ml-2">{companyName}</h1>
    </div>
  );
};

export default Logo;
