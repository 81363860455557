import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import Navigation from "../components/Navigation";
import Header from "../components/Header";
import headerData from "../content/header.json";

const title = "TOHA Heavy Industries - Onboarding";

const apiBasePath =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_API_URL
    : process.env.REACT_APP_PROD_API_URL;

const Onboarding: React.FC = () => {
  const [secretWord, setSecretWord] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiBasePath}/onboarding`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Include cookies in the request
        body: JSON.stringify({ secretWord }),
      });

      if (!response.ok) {
        throw new Error("Failed to update secret word");
      }

      // Redirect to dashboard after successful submission
      navigate("/dashboard");
    } catch (error) {
      console.error("Error updating secret word:", error);
      // Handle error here
    }
  };

  return (
    <>
      <Header title={title} description={headerData.description} />
      <Layout>
        <Navigation />
        <div className="flex flex-col items-center mt-10">
          <div className="bg-slate-900 shadow-md rounded-lg p-6 max-w-md w-full text-center">
            <h3 className="text-3xl font-semibold mb-5 text-secondary">
              {title}
            </h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  className="block text-sm font-bold mb-2"
                  htmlFor="secretWord"
                >
                  Secret Word
                </label>
                <input
                  className="shadow appearance-none border text-black p-4 rounded w-full py-2 px-3leading-tight focus:outline-none focus:shadow-outline"
                  id="secretWord"
                  type="text"
                  placeholder="Enter your secret word"
                  value={secretWord}
                  onChange={(e) => setSecretWord(e.target.value)}
                  required
                />
              </div>
              <button
                type="submit"
                className="bg-slate-700 hover:bg-slate-800 text-secondary font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Onboarding;
