import React, { useContext } from "react";
import "@fontsource/inter";
import Footer from "./Footer";
import { ChildrenProps } from "../types";
import footerData from "../content/footer.json";
import { ThemeContext, ThemeProvider } from "../context/ThemeContext";

const Layout: React.FC<ChildrenProps> = ({ children }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <>
      <ThemeProvider>
        <div className={themeContext?.theme === "dark" ? "dark" : ""}>
          <div className="bg-white dark:bg-black text-black dark:text-white p-0 w-screen">
            <main className="flex flex-col py-8 pb-8 min-h-screen max-w-6xl m-auto">
              {children}
            </main>
            <Footer
              year={footerData.year}
              company={footerData.company}
              about={footerData.about}
            />
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};

const App: React.FC<ChildrenProps> = ({ children }) => (
  <ThemeProvider>
    <Layout>{children}</Layout>
  </ThemeProvider>
);

export default App;
