import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { buttonVariants } from "../utils/button";
import Navigation from "../components/Navigation";
import Layout from "../components/Layout";
import Header from "../components/Header";
import { useAuth } from "../hooks/useAuth";
import LoadingPage from "./LoadingPage";

import headerData from "../content/header.json";

const authBasePath =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_ORY_SDK_URL
    : process.env.REACT_APP_PROD_ORY_SDK_URL;

const apiBasePath =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_API_URL
    : process.env.REACT_APP_PROD_API_URL;

const Dashboard: React.FC = () => {
  const { session, logoutUrl, error } = useAuth();
  const userEmail = session?.identity?.traits?.email || "friend";
  const [userData, setUserData] = useState<any>(null);
  const navigate = useNavigate();

  const curlCommand = `curl -X GET ${apiBasePath}/user-data`;

  useEffect(() => {
    const fetchUserData = async () => {
      if (session && !userData) {
        try {
          const response = await fetch(`${apiBasePath}/user-data`, {
            headers: {
              "Content-Type": "application/json",
            },
            method: "GET",
            credentials: "include", // Include cookies in the request
          });

          if (response.status === 404) {
            navigate("/onboarding");
            return;
          }

          if (!response.ok) {
            throw new Error("Failed to fetch user data");
          }

          const data = await response.json();
          setUserData(data);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    if (session) {
      fetchUserData();
    }

    // Also navigate to onboarding page if secret word is missing
    if (userData && !userData.secretWord) {
      navigate("/onboarding");
    }
  }, [session, userData, navigate]);

  useEffect(() => {
    if (!session && error && error.response && error.response.status === 401) {
      window.location.href = `${authBasePath}/ui/login`;
    }
  }, [session, error]);
  if (!session) {
    return <LoadingPage />;
  }

  return (
    <>
      <Header title={headerData.title} description={headerData.description} />
      <Layout>
        <Navigation session={session} logoutUrl={logoutUrl} />
        <div className="flex flex-col items-center mt-10">
          <h3 className="text-3xl font-semibold mb-5">Welcome, {userEmail}!</h3>
          <div className="shadow-2xl rounded-lg p-6 m-6 max-w-md w-full text-center">
            <p className="text-xl font-bold text-primary mb-3">
              This is your random secret sentence, fetched from the secured
              backend API:
            </p>
            <p className="text-2xl font-bold text-secondary mb-6">
              {userData ? userData.uniqueString : "Loading..."}
            </p>
            {userData && (
              <div className="text-left">
                <p className="text-sm">
                  Your Ory User ID:{" "}
                  <span className="font-semibold text-secondary">
                    {session.identity.id}
                  </span>
                </p>
                <p className="text-sm">
                  Your Database User ID:{" "}
                  <span className="font-semibold text-secondary">
                    {userData.id}
                  </span>
                </p>
                {session.identity.id === userData.id ? (
                  <p className="text-sm text-green-500">✅ IDs match</p>
                ) : (
                  <p className="text-sm text-red-500">❌ IDs do not match</p>
                )}
                <p className="text-sm">
                  Your Email:{" "}
                  <span className="font-semibold text-secondary">
                    {session.identity.traits.email}
                  </span>
                </p>
                <p className="text-sm">
                  Your Secret Word:{" "}
                  <span className="font-semibold text-secondary">
                    {userData && userData.secretWord}
                  </span>
                </p>
                <div className="flex flex-col items-center my-4">
                  <a
                    href={"/onboarding"}
                    className={buttonVariants({
                      variant: "outline",
                      size: "md",
                    })}
                  >
                    Edit Secret Word
                  </a>
                </div>
              </div>
            )}
            <a
              href={logoutUrl}
              className={buttonVariants({ variant: "outline", size: "md" })}
            >
              Logout
            </a>
          </div>
          <div className="shadow-2xl rounded-lg p-6 m-6 max-w-md w-full text-centerbox">
            <p>
              Copy and execute the following curl command in your terminal to
              test fetching data without authentication:
            </p>
            <code>{curlCommand}</code>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Dashboard;
