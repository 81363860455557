import React from "react";
import { ComponentProps } from "../types";

const SectionWithList: React.FC<ComponentProps> = ({
  overline,
  title,
  description,
  items,
}) => {
  return (
    <section className="section p-8 max-w-screen-md mx-auto">
      <h2 className="text-md font-semibold mb-4 text-secondary text-center">
        {overline}
      </h2>
      <h2 className="text-3xl font-semibold mb-4 text-secondary text-center">
        {title}
      </h2>
      <p className="text-xl font-semibold mb-4 text-center">{description}</p>
      {items.map((item, index) => (
        <div key={index} className="item">
          <h3 className="item-title text-xl font-semibold">{item.title}</h3>
          <p className="item-description py-2">{item.description}</p>
        </div>
      ))}
    </section>
  );
};

export default SectionWithList;
