import React from "react";
import Layout from "../components/Layout";
import Navigation from "../components/Navigation";
import Header from "../components/Header";
import headerData from "../content/header.json";

const title = "TOHA Heavy Industries - Loading";

const LoadingPage: React.FC = () => {
  return (
    <>
      <Header title={title} description={headerData.description} />
      <Layout>
        <Navigation />
        <div className="flex flex-col items-center justify-center h-screen">
          <svg
            className="animate-spin h-10 w-10 text-blue-500 mb-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM12 20c2.485 0 4.753-.9 6.516-2.385l-3-5.196A8.018 8.018 0 0112 20zm8-8c0-1.673-.516-3.234-1.4-4.52l-3 2.647c.484.543.876 1.143 1.168 1.799A6.008 6.008 0 0020 12h-4zm-9.516-3.485l-3 5.196c1.389 1.215 3.192 1.953 5.16 1.953V12c-1.291 0-2.484-.49-3.516-1.485zM12 4c-2.485 0-4.753.9-6.516 2.385l3 5.196C9.516 15.51 10.709 16 12 16v4c3.042 0 5.824-1.135 7.938-3l-3-2.647A7.962 7.962 0 0112 20h4c0-2.9-1.247-5.513-3.234-7.345l-3.482 3.482A5.984 5.984 0 0014 12V8c0-1.689-.683-3.219-1.789-4.344l-3.482 3.482C8.513 7.246 10 9.937 10 12v.764l-.003.001L9.792 12 9 11.207l-2.853-2.853L5.293 9.707l4.242 4.242A1 1 0 0010 14h4a1 1 0 00.928-.629l3.482-7.481A1 1 0 0017 5V1a1 1 0 00-1-1h-4a1 1 0 00-1 1v3a1 1 0 001 1h1.764L12 7.207 10.646 5.853 9.293 7.207l.358.358L8.793 8.707l-3.88-3.88A7.984 7.984 0 0012 4z"
            ></path>
          </svg>
          <p>Loading...</p>
        </div>
      </Layout>
    </>
  );
};

export default LoadingPage;
