import React from "react";
import { ComponentProps } from "../types";

const SectionWithItems: React.FC<ComponentProps> = ({
  overline,
  title,
  description,
  items,
}) => {
  return (
    <section className="section p-8 max-w-screen-md mx-auto">
      <h2 className="text-md font-semibold mb-4 text-secondary text-center">
        {overline}
      </h2>
      <h2 className="text-3xl font-semibold mb-4 text-secondary text-center">
        {title}
      </h2>
      <p className="text-xl font-semibold mb-4 text-center">{description}</p>
      <div className="pt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {items.map((item, index) => (
          <div
            key={index}
            className="bg-white dark:bg-black border border-black dark:border-white p-8 rounded-md shadow-md"
          >
            <h3 className="item-title text-xl font-semibold">{item.title}</h3>
            <p className="item-description py-2">{item.description}</p>
            {item.items && item.items.length > 0 && (
              <ul className="item-list list-disc pl-5 mt-2">
                {item.items.map((subItem, subIndex) => (
                  <li key={subIndex} className="py-1">
                    {subItem}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default SectionWithItems;
