import React from "react";
import Hero from "../components/Hero";
import { useAuth } from "../hooks/useAuth";
import Navigation from "../components/Navigation";
import Layout from "../components/Layout";
import Header from "../components/Header";
import CTA from "../components/CTA";
import SectionWithList from "../components/SectionWithList";
import SectionWithItems from "../components/SectionWithItems";

import faqData from "../content/faq.json";
import featuresData from "../content/features.json";
import pricingData from "../content/pricing.json";
import ctaData from "../content/cta.json";
import headerData from "../content/header.json";

const title = "TOHA Heavy Industries - Home";

const Home: React.FC = () => {
  const { session, logoutUrl } = useAuth();
  return (
    <>
      <Header title={title} description={headerData.description} />
      <Layout>
        <Navigation session={session} logoutUrl={logoutUrl} />
        <Hero />
        <SectionWithItems
          overline={featuresData.overline}
          title={featuresData.title}
          description={featuresData.description}
          items={featuresData.items}
        />
        <SectionWithItems
          overline={pricingData.overline}
          title={pricingData.title}
          description={pricingData.description}
          items={pricingData.items}
        />
        <SectionWithList
          overline={faqData.overline}
          title={faqData.title}
          description={faqData.description}
          items={faqData.items}
        />
        <CTA
          title={ctaData.title}
          description={ctaData.description}
          buttonText={ctaData.buttonText}
          buttonUrl={ctaData.buttonUrl}
        />
      </Layout>
    </>
  );
};

export default Home;
